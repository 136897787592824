.tooltip-cell__text {
  display: none;
  color: black;
  text-decoration: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  font-weight: normal;
}
.tooltip-cell__text:hover {
  display: none !important;
}
.tooltip-cell:hover > .tooltip-cell__text {
  position: absolute;
  display: block;
  border: 1px solid;
  border-radius: 3px;
  border-color: black;
  background-color: white;
  padding: 4px 6px;
  font-size: 80%;
}
