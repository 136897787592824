.home {
  max-width: 900px;
  font-weight: normal;
  text-align: justify;
}
.home__title_photo {
  display: block;
}
.home__youtube_video {
  text-align: center;
}
