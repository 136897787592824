.detail__member_icon {
  width: 50px;
  margin: 0px;
  vertical-align: middle;
  margin-bottom: 22px;
}
.detail__member_photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 900px;
  height: auto;
}
.detail__header {
  font-size: 350%;
  letter-spacing: -3px;
}
