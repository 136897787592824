* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 100ms;
}
body {
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
a {
  color: #AAA;
}
a:hover {
  color: black;
}
.grey_text {
  color: #AAA;
}
