.header--1,
.header--2,
.header--3 {
  text-align: center;
  white-space: nowrap;
}
.header--1 {
  font-size: 450%;
  margin: 20px;
  text-transform: uppercase;
}
.header--2 {
  font-size: 250%;
  margin-top: 40px;
  margin-bottom: 20px;
}
.header--3 {
  font-size: 150%;
  margin-top: 20px;
  margin-bottom: 0px;
}
