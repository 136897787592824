.level-component {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.level-component__info {
  margin-top: 20px;
}
.level-component__image {
  float: left;
  margin: 20px 20px 20px 0px;
}
