.menu {
  position: fixed;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  border-bottom: 1px solid white;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.menu__item {
  margin: 10px 10px;
}
@media only screen and (max-width: 600px) {
  .menu__item {
    margin: 10px 0px;
  }
}
.menu a {
  color: white;
  text-decoration: none;
  padding: 10px;
}
.menu a:hover {
  color: white;
  text-decoration: underline;
}
